<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Reporte de Cajas</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
    <div
      class="
        flex
        align-items-center
        flex-column
        lg:justify-content-center lg:flex-row
      "
    >
      <h4><strong>REPORTE GENERAL DE CAJAS</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <label for="sucursal"><strong>SUCURSAL: </strong></label>
            <Dropdown
              v-model="sucursalSelected"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for="usuarios"><strong>USUARIOS/CAJEROS: </strong></label>
            <Dropdown
              v-model="usuarioSelected"
              :options="usuarios"
              optionLabel="name"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for="fecha_apertura_desde"><strong>FECHA APERTURA DESDE: </strong></label>
            <Calendar
              id="fecha_apertura_desde"
              v-model="fecha_apertura_desde"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="fecha_cierre_hasta"><strong>FECHA CIERRE HASTA: </strong></label>
            <Calendar
              id="fecha_cierre_hasta"
              v-model="fecha_cierre_hasta"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for=""><strong>ESTADO DE CAJA: </strong></label>
            <Dropdown
              v-model="estadoSelected"
              :options="estados"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for="fecha_vencimiento_hasta"><strong>ACCIONES: </strong></label>
            <Button
              label="APLICAR FILTROS"
              icon="pi pi-search"
              class="p-button-primary mr-2"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="reporte_stock"
        key="id"
        :value="cajas"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
        :rows="10"
        :paginator="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 100, 500, 1000, 5000, 10000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Reportes de Cajas"
      >
        <template #header>
          <div
            class="
              flex flex-column
              md:flex-row md:justify-content-end md:align-items-end
            "
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Caja..."
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <Button
                label="PDF Cajas"
                type="button"
                icon="pi pi-file-pdf"
                class="p-button-outlined p-button-danger"
                v-tooltip.top="'Exportar PDF Cajas'"
                :loading="generando_pdf"
                :disabled="generando_pdf"
                @click="generarPDF()"
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button
                label="EXCEL Exportar"
                type="button"
                icon="pi pi-file-excel"
                class="p-button-outlined p-button-success"
                v-tooltip.top="'Exportar Excel Cajas'"
                :loading="exportando"
                :disabled="exportando"
                @click="exportCajas()"
              />
            </span>
          </div>
        </template>
        <template #loading
          ><div class="flex align-items-center justify-content-center">
            <ProgressSpinner /></div
        ></template>
        <template #empty>
          <span class="p-invalid">
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="id" header="CÓD CAJA" :sortable="true"></Column>
        <Column field="sucursal" header="SUCURSAL" :sortable="true">
          {{ sucursal }}
        </Column>
        <Column field="usuario" header="USUARIO" />
        <Column
          field="fecha_apertura"
          header="FECHA APERTURA"
          :sortable="true"
        ></Column>
        <Column
          field="fecha_cierre"
          header="FECHA CIERRE"
          :sortable="true"
        ></Column>
        <Column field="saldo_inicial" header="SALDO INICIAL" class="text-right">
					<template #body="{ data }">
						{{ convertirNumeroGermanicFormat(data.saldo_inicial || 0) }}
					</template>
				</Column>
        <Column field="total_ventas" header="TOTAL VENTAS" class="text-right">
				<template #body="{ data }">
					{{ convertirNumeroGermanicFormat(data.total_ventas || 0) }}
				</template>
				</Column>
        <Column field="total_ingresos" header="INGRESOS" class="text-right">
					<template #body="{ data }">
						{{ convertirNumeroGermanicFormat(data.total_ingresos || 0) }}
					</template>
				</Column>
        <Column field="total_egresos" header="EGRESOS" class="text-right">
					<template #body="{ data }">
						{{ convertirNumeroGermanicFormat(data.total_egresos || 0) }}
					</template>
				</Column>
        <Column field="monto_entregar" header="MONTO ENTREGAR" class="text-right">
					<template #body="{ data }">
						{{ convertirNumeroGermanicFormat(data.monto_entregar || 0) }}
					</template>
				</Column>
        <Column field="saldo_caja" header="SALDO CAJA" class="text-right">
					<template #body="{ data }">
						{{ convertirNumeroGermanicFormat(data.saldo_caja || 0) }}
					</template>
				</Column>
        <Column field="estado" header="ESTADO CAJA">
          <template #body="{ data }">
						<span
							class="product-badge status"
							:class="{
								'status-instock': data.estado === 1,
								'status-outofstock': data.estado === 2,
							}"
						>
							{{ textoEstado(data.estado) }}
						</span>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import SucursalService from "@/service/SucursalService";
import CajaService from "@/service/CajaService";
import UserService from "@/service/UserService";

export default {
  data() {
    return {
      cajas: [],
      sucursalSelected: 0,
      estadoSelected: 0,
      estados: [
        { nombre: "TODOS", id: 0 },
        { nombre: "ABIERTA", id: 1 },
        { nombre: "CERRADA", id: 2 },
      ],
      sucursales: [{ id: 0, nombre: "TODAS" }],
      usuarioSelected: 0,
      usuarios: [{ id: 0, name: "TODOS" }],
      enviando: false,
      generando_pdf: false,
      exportando: false,
      fecha_apertura_desde: null,
      fecha_cierre_hasta: null,
      buscar: {
        global: { matchMode: FilterMatchMode.CONTAINS, value: null },
      },
    };
  },
  sucursalService: null,
  cajaService: null,
  userService: null,
  created() {
    this.sucursalService = new SucursalService();
    this.cajaService = new CajaService();
    this.userService = new UserService();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarUsuarios();
  },
  methods: {
		convertirNumeroGermanicFormat(numero) {
			return new Intl.NumberFormat("de-DE", {
				minimumFractionDigits: 2,
			}).format(numero);
		},
    exportCajas() {
      if (this.cajas.length > 0) {
        let datos = {
          sucursal: this.sucursalSelected,
          usuario: this.usuarioSelected,
          estado: this.estadoSelected,
          fecha_apertura_desde: this.fecha_apertura_desde,
          fecha_cierre_hasta: this.fecha_cierre_hasta,
        };
        this.cajaService.exportCajasAll(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Exportación exitosa",
            detail: "Se ha exportado correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    generarPDF() {
      if (this.cajas.length > 0) {
        this.generando_pdf = true;
        let datos = {
          sucursal: this.sucursalSelected,
          usuario: this.usuarioSelected,
          estado: this.estadoSelected,
          fecha_apertura_desde: this.fecha_apertura_desde,
          fecha_cierre_hasta: this.fecha_cierre_hasta,
        };
        this.cajaService
          .generarPDF(datos)
          .then(() => {
            this.generando_pdf = false;
            this.$toast.add({
              severity: "success",
              summary: "Éxito",
              detail: "Se generó el PDF correctamente",
              life: 3000,
            });
          })
          .catch((error) => {
            console.log(error);
            this.generando_pdf = false;
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "No hay datos para generar el PDF",
          life: 3000,
        });
      }
    },
    BuscarFiltro() {
      this.cajas = [];
      this.enviando = true;
      this.cargarCajasFiltros();
    },
    cargarCajasFiltros() {
      let datos = {
        sucursal: this.sucursalSelected,
        usuario: this.usuarioSelected,
        fecha_apertura_desde: this.fecha_apertura_desde,
        fecha_cierre_hasta: this.fecha_cierre_hasta,
        estado: this.estadoSelected,
      };
      this.cajaService
        .filtrarCajas(datos)
        .then((response) => {
          this.cajas = response.cajas;
          this.enviando = false;
        })
        .catch(() => {
          this.enviando = false;
        });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarUsuarios() {
      this.userService
        .getUsersAll()
        .then((response) => {
          response.forEach((usuario) => {
            this.usuarios.push(usuario);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    textoEstado(estado) {
      if (estado == 1) {
        return "ABIERTA";
      } else if (estado == 2) {
        return "CERRADA";
      } else {
        return "ANULADA";
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
}
</style>
